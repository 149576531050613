// Importando módulos necesarios
import React, { useEffect, useState } from "react";
import "../../../../../styles/registro/global.scss";
import "../../../../../styles/registro/prospectos/pasos/pagoReferencia.scss";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Container,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Card,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles"; // Herramientas de MUI para el tema
import axios from "axios";
import PagoReferenciaPDF from "./PagoReferenciaPDF";
import { urlsAPIs } from "../../../../../helpers/urlsAPIs";

import { StyledButton4 } from "../../../../customButtons/StyledButton";
import { toast } from "sonner";
import { globalMessage } from "../../../../../helpers/globalMessage";
import CompletedStep from "../../CompletedStep";
import AccordionCustomized from "./PagoReferenciaCollapsible";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { LANG } from "./LANG";
import PagoCompletado from "./PagoCompletado";
import { useLogin } from "../../../../../hooks/usuariosApi/useLogin";
import PaymentLocations from "./PaymentLocations";
// Tema por defecto
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#483e6b",
    },
  },
});
/**
 * Componente pago deposito en garantia
 * @author ERL 2023-07-17 12:40 pm
 * @param {object} headers - Headers para las llamadas API.
 * @returns {JSX.Element}
 */
const PagoReferencia = ({ headers }) => {
  const [loadingComponent, setLoadingComponent] = useState(true);
  const { logOutUsuario: logout } = useLogin();
  const [aReferencias, setReferencias] = useState([]);
  const [todosPagados, setTodosPagados] = useState(false);
  // Renderizando el componente
  const logo7Eleven = "/assets/comerciosPago/7ELogo.png";
  const logoCIRCLE = "/assets/comerciosPago/CIRCLE.png";
  const logoSORIANA = "/assets/comerciosPago/SORIANA.png";
  const logoBBVA = "/assets/comerciosPago/BBVA.png";
  const logoSPEI = "/assets/comerciosPago/SPEI.svg";
  const [aComerciosTransferencia, setComerciosTransferencia] = useState([
    {
      sNombre: "SPEI",
      description: "Depósito inmediato",
      icon: logoSPEI,
      time: null,
      fee: "Sin comisión",
      accountNumber: "6461801460135056XX",
      aInstructiones: [
        "Realiza una transferencia a Cuenta CLABE, desde la aplicación o portal de otro banco.",
        "Selecciona STP (Sistema de Transferencias y Pagos) como banco de destino.",
        "Ingresa el nombre del titular de la cuenta, el monto de la transferencia y confirma.",
        "¡Listo! El depósito se verá reflejado en cuestión de minutos.",
        "Toma una captura de pantalla de tu transferencia en caso de futuras aclaraciones.",
      ],
    },
  ]);

  const [aComerciosEfectivo, setComerciosEfectivo] = useState([
    {
      sNombre: "BBVA",
      description: "Depósito en",
      icon: logoBBVA,
      time: "2hr",
      fee: "Sin comisión",
      accountNumber: "",
      aInstructiones: [
        "Acude a la sucursal bancaria de BBVA.",
        "Selecciona en el cajero automático o si estás en ventanilla menciona pago de servicios.",
        "Ingresa el convenio 1420712.",
        "Registra el monto a depositar.",
        "¡Listo! En un máximo de 2 horas el dinero estará reflejado.",
        "Guarda tu recibo.",
      ],
    },
  ]);
  const [aComerciosEfectivoNotarial, setComerciosEfectivoNotarial] = useState([
    {
      sNombre: "SORIANA",
      description: "Depósito en",
      icon: logoSORIANA,
      time: "1hr",
      fee: 12,
      accountNumber: "",
      aInstructiones: [
        "Acude a la sucursal y dile al cajero que quieres realizar un pago de servicio PayCash.",
        "Muéstrale el código de barras o díctale la referencia de 13 dígitos.",
        "Paga el monto en efectivo, más la comisión de $12.00 MXN que cobra Soriana.",
        "Recuerda que en esta operación, Soriana actúa a nombre y por cuenta de Inteligencia en Finanzas, S.A.P.I. de C.V., IFPE.",
        "¡Listo! En un máximo  de 1 hora el pago estará reflejado.",
        "Guarda tu recibo.",
      ],
    },
    {
      sNombre: "SEVEN",
      description: "Depósito en",
      icon: logo7Eleven,
      time: "30m",
      fee: 12,
      accountNumber: "",
      aInstructiones: [
        "Acude a la sucursal y dile al cajero que quieres realizar un Pago de servicio PayCash.",
        "Muéstrale el código de barras o díctale la referencia de 13 dígitos.",
        "Paga el monto en efectivo, más la comisión de $12.00 MXN que cobra 7-Eleven.",
        "Recuerda que en esta operación, 7-Eleven actúa a nombre y por cuenta de Inteligencia en Finanzas, S.A.P.I. de C.V., IFPE.",
        "¡Listo! En un máximo de 30 minutos el pago estará reflejado.",
        "Guarda tu recibo.",
      ],
    },
    {
      sNombre: "CIRCLE (K)",
      description: "Depósito en",
      icon: logoCIRCLE,
      time: "30m",
      fee: 12,
      accountNumber: "",
      aInstructiones: [
        "Acude a la sucursal y dile al cajero que quieres realizar un pago de servicio CIRCLE (K).",
        "Muéstrale el código de barras o díctale la referencia de 13 dígitos.",
        "Paga el monto en efectivo, más la comisión de $12.00 MXN que cobra CIRCLE (K).",
        "Recuerda que en esta operación, 7-Eleven actúa a nombre y por cuenta de Inteligencia en Finanzas, S.A.P.I. de C.V., IFPE.",
        "¡Listo! En un máximo de 30 minutos el pago estará reflejado.",
        "Guarda tu recibo.",
      ],
    },
  ]);

  /**
   * Este efecto se activa al montar el componente. Realiza una petición GET a la API para obtener
   * los datos de referencia de pago para el cliente. Una vez obtenidos los datos, se establecen
   * en el estado del componente para su posterior uso.
   *
   * @author ERL 2023-07-17 12:40 pm
   * @hook useEffect - Hook de React que se activa al montar el componente.
   * @returns {void}
   */
  useEffect(() => {
    const getData = async () => {
      const id_cliente = sessionStorage.getItem("ci");
      try {
        const response = await axios.get(
          `${urlsAPIs.urlReferenciaPago}/${id_cliente}`,
          {
            params: {
              sTipo: "P", // Pago Referencia
            },
            headers: headers,
          }
        );
        const { nCodigo, sMensaje, data: oReferenciaForm } = response?.data;
        if (nCodigo === 0) {
          setReferencias(oReferenciaForm);
          const todosEstanPagados =
            oReferenciaForm?.length > 0 &&
            oReferenciaForm.every(
              (obj) => obj.sStatusPagoGarantia === "PAGADA"
            );
          setTodosPagados(todosEstanPagados);
        } else {
          toast.error(sMensaje);
        }
      } catch (error) {
        const { responseCode = 0, message = "" } = error?.response.data;
        if (responseCode === "401" && message === "jwt expired") {
          logout();
        } else {
          toast.error(globalMessage.errorServidor);
        }
      } finally {
        setLoadingComponent(false);
      }
    };
    getData();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          maxWidth: { xs: "100%", sm: "400px", md: "600px", lg: "1600px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CssBaseline />

        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "3% 0",
          }}
        >
          <Box
            sx={{
              mt: { xl: 3, xs: 0 },
              mx: { xl: 3, xs: 0 },
              mb: { xl: 3, xs: 0 },
              minHeight: { lg: "500px" },
            }}
          >
            <Grid container spacing={2} sx={{ ml: 0, mt: 0, width: "100%" }}>
              <Grid
                container
                sx={{
                  m: "0 auto",
                  p: 0,
                  maxWidth: { xl: "800px", xs: "100%" },
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "15px",
                }}
              >
                {loadingComponent ? (
                  <Grid
                    sx={{
                      height: "50vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="primary" size={"100px"} />
                  </Grid>
                ) : todosPagados ? (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {todosPagados && (
                      <>
                        <PagoCompletado />
                      </>
                    )}
                  </Container>
                ) : (
                  <>
                    {aReferencias?.map((ref) => {
                      const icon =
                        ref?.sStatusPagoGarantia === "PAGADA" ? (
                          <CheckCircleOutlineIcon
                            sx={{
                              color:
                                ref?.sStatusPagoGarantia === "PAGADA"
                                  ? "green"
                                  : "orange",
                            }}
                          />
                        ) : (
                          <WarningAmberIcon
                            sx={{
                              color:
                                ref?.sStatusPagoGarantia === "PAGADA"
                                  ? "green"
                                  : "orange",
                            }}
                          />
                        );
                      return (
                        <div key={ref.sNombre} style={{ width: "100%" }}>
                          <AccordionCustomized
                            title={ref.sTitulo}
                            icon={icon}
                            iconColor={
                              ref?.sStatusPagoGarantia === "PAGADA"
                                ? "green"
                                : "orange"
                            }
                            status={ref?.sStatusPagoGarantia}
                            content={
                              <Grid sx={{ margin: "25px 0" }}>
                                {ref?.sStatusPagoGarantia === "PAGADA" ? (
                                  <CompletedStep
                                    sMensaje={LANG.labelPagado}
                                    sDesc={''}
                                  />
                                ) : (
                                  <>
                                    <PaymentLocations
                                      sGroupTitle={
                                        "Por transferencia electrónica"
                                      }
                                      aComercios={aComerciosTransferencia}
                                      sPaymentType={"T"}
                                      sReferenceSPEI={ref.sReferenciaSPEI}
                                    />
                                    <PaymentLocations
                                      sGroupTitle={"En efectivo"}
                                      aComercios={
                                        ref.sTitulo === "Gastos notariales"
                                          ? aComerciosEfectivoNotarial
                                          : aComerciosEfectivo
                                      }
                                      sPaymentType={"E"}
                                    >
                                      <PagoReferenciaPDF
                                        sNombre={`${ref.sNombre} ${ref.sApellidoP} ${ref.sApellidoM}`}
                                        nReferencia={
                                          ref.sRefencia !== ""
                                            ? ref.sRefencia
                                            : 0
                                        }
                                        nMonto={ref.nMonto}
                                        sTitulo={ref.sTitulo}
                                        nIdProvedorPago={ref.nIdProvedorPago}
                                      />
                                    </PaymentLocations>
                                  </>
                                )}
                              </Grid>
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PagoReferencia;
