import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Collapse,
  Box,
  Divider,
  Snackbar,
} from "@mui/material";
import { ExpandMore, ExpandLess, ContentCopy } from "@mui/icons-material";

export default function PaymentLocations({
  sGroupTitle,
  aComercios,
  sPaymentType,
  sReferenceSPEI,
  children,
}) {
  const [expandedItem, setExpandedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

   const handleItemClick = (index) => {
    setExpandedItem(expandedItem === index ? null : index);
  };

  const handleCopyClick = (accountNumber) => {
    navigator.clipboard.writeText(accountNumber).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Card sx={{ width: "100%", margin: "auto", mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {sGroupTitle}
        </Typography>
        <List>
          {aComercios.map((oComercio, index) => (
            <React.Fragment key={oComercio.sNombre}>
              <ListItem
                button
                onClick={() => handleItemClick(index)}
                sx={{
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                }}
              >
                <ListItemIcon
                  style={{
                    width: "100px",
                    boxSizing: "border-box",
                    padding: "1.5%",
                  }}
                >
                  {oComercio?.icon && (
                    <img
                      src={oComercio.icon}
                      alt=""
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={oComercio.sNombre}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {oComercio.description}
                      </Typography>
                      {oComercio.time && ` - ${oComercio.time}`}
                      {oComercio.fee && (
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.secondary"
                          sx={{ display: "block" }}
                        >
                          Comisión:{" "}
                          {typeof oComercio.fee === "number"
                            ? `$${oComercio.fee}`
                            : oComercio.fee}
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
                {expandedItem === index ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={expandedItem === index}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ p: 2, bgcolor: "background.paper" }}>
                  {sPaymentType === "T" && (
                    <>
                      <Typography variant="subtitle1" gutterBottom>
                        Transferencia
                      </Typography>

                      <Typography color="textSecondary" variant="subtitle2">
                        No. de cuenta CLABE
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          bgcolor: "grey.50",
                          p: 2,
                          borderRadius: 1,
                          mt: 1,
                          mb: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                          {sReferenceSPEI}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            handleCopyClick(sReferenceSPEI)
                          }
                          size="small"
                          aria-label="copiar número de cuenta"
                        >
                          <ContentCopy />
                        </IconButton>
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Banco: Sistema de Transferencias y Pagos
                      </Typography>
                      <hr />
                      <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                          
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Puedes realizar una transferencia por SPEI, desde el
                          portal de internet o app móvil de cualquier banco en
                          México.
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                    Instrucciones
                  </Typography>
                  <List dense>
                    {oComercio.aInstructiones.map((instruction, idx) => (
                      <ListItem key={idx} sx={{ px: 0 }}>
                        <ListItemText
                          primary={`${idx + 1}. ${instruction}`}
                          primaryTypographyProps={{
                            variant: "body2",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {children && children}
              </Collapse>
              {index < aComercios.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Número de cuenta copiado al portapapeles"
      />
    </Card>
  );
}
