import React, { useRef } from 'react';
import { useBarcode } from 'next-barcode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { StyledButton2 } from '../../../../customButtons/StyledButton';
import { toast } from 'sonner';
import { globalMessage } from '../../../../../helpers/globalMessage';
/**
 * Componente para generar pdf y codigo de barras
 * @author ERL 2023-07-12 10:36 am 
 * @param {string} sNombre - Nombre del prospecto
 * @param {number} nReferencia - Numero de referencia del prospecto
 * @param {number} nMonto - Monto a pagar
 * @returns {JSX.Element} Componente de cuestionaro de preguntas
*/
function PagoReferenciaPDF({ sNombre, nReferencia, nMonto, sTitulo, nIdProvedorPago }) {
  const componentRef = useRef();
  const fechaActual = new Date();
  const Y = fechaActual.getFullYear(); //Año
  const M = fechaActual.getMonth() + 1;  //Mes
  const D = fechaActual.getDate(); //Dia
  const refPdfNombre = `Referencia_${Y}${M.toString().padStart(2, '0')}${D.toString().padStart(2, '0')}`;
  /**
   * Función para descargar el contenido de un componente como un archivo PDF
   * @author ERL 2023-07-17 12:50 pm 
   * @returns {void} 
   */
  const downloadPdf = async () => {
    try {
      const canvas = await html2canvas(componentRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // especificar el formato

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 50; // restar el margen
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const x = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;

      pdf.addImage(imgData, 'PNG', x, 10, pdfWidth, pdfHeight); // agregar el margen
      pdf.save(`${refPdfNombre}.pdf`);
    } catch (error) {      
      toast.error(globalMessage.errorCrearPDF)
    }
  };
  const nFormattedMonto = nMonto?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const { inputRef } = useBarcode({
    value: nReferencia,
    options: {
      format: "CODE128",
      lineColor: "#483e6b",
      width: 2,
      height: 100,
      displayValue: true,
    }
  });
  const logoPaycashDG = "/assets/logosPagoRef.png";    
  const logoPaycashGN = "/assets/Paycash_Notariales.png";    
  const logoDappDG = '/assets/GARANTIA.png'
  const logoDappGN = '/assets/NOTARIALES.png'

  const logosPago = {
    1: sTitulo === 'Gastos notariales' ? logoPaycashGN : logoPaycashDG,    
    2: sTitulo === 'Gastos notariales' ? logoPaycashGN : logoPaycashDG
    //2: sTitulo === 'Gastos notariales' ? logoDappGN : logoDappDG
  }


  return (
    <div className='pdf' >
      <div ref={componentRef} className='pdf-container'>
        <div className='pdf-item-img'>
        <img src={logosPago[nIdProvedorPago]} alt="" />
        </div>
        <div className='pdf-item'>
          <div className='pdf-item-txt'>
            <p>{sTitulo}</p>
            <p>Referencia</p>
          </div>
          
          <div className='pdf-item-barcode' style={{visibility:`${nReferencia > 0 ? "":"hidden"}`, }}>
            <svg ref={inputRef} />
          </div>
          <div className='pdf-item-numbers'>            
            <span>Monto: ${nFormattedMonto} MXN</span>
          </div>
          <div className='pdf-item-txt'>
            <p>{sNombre}</p>
          </div>
        </div>
      </div>
      {nReferencia > 0 && <StyledButton2 width="100%" onClick={downloadPdf}>Descargar PDF</StyledButton2>}
      
    </div>
  );
}

export default PagoReferenciaPDF;
