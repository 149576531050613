// Importando módulos necesarios
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"; // Hook para la navegación

import axios from "axios";
import { toast } from "sonner";
import { Controller, useForm } from "react-hook-form";
import { urlsAPIs } from "../../../helpers/urlsAPIs";
import { globalMessage } from "../../../helpers/globalMessage";
import { LANG } from "../../../components/registro/prospectos/pasos/cuestionario/LANG";
import { useLogin } from "../../usuariosApi/useLogin";

/**
 * Registrar nuevo usuario
 * @author ERL 2023-06-09 12:21 pm
 * @param {object} config - Datos del usuario para el registro
 * @field {function} nextStep - Función para ir al siguiente paso del proceso
 * @returns object
 */
export function useCuestionario(config = {}) {
  const { nextStep, headers } = config;
  const { logOutUsuario: logout } = useLogin();
  const [unidadesInteres, setUnidadesInteres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEntidad, setLoadingEntidad] = useState(null);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    shouldFocusError: true, // Esto debería estar habilitado por defecto
  });

  const [nIdUsuario, setNIdUsuario] = useState(0);
  // Hook para la navegación
  const navigate = useNavigate();
  const jwt = sessionStorage.getItem("t");
  useEffect(() => {
    if (jwt) {
      if (jwt === "" || jwt.length === 0) {
        navigate("/login");
      } else {
        const id = sessionStorage.getItem("u");
        setNIdUsuario(id);
      }
    } else {
      navigate("/login");
    }
  }, [jwt]);

  const [selectedFields, setSelectedFields] = useState({});
  const [visibleFields, setVisibleFields] = useState({});
  const [selectedRadio, setSelectedRadio] = useState(0);
  const [selectedSelect, setSelectedSelect] = useState(0);
  const [lada, setLada] = useState("+52"); // Por defecto la lada de México.
  const radioRefs = useRef([]);
  const firstInputValues = watch(); // Obtener todos los valores del formulario
  const [nTelefono, setTelefono] = useState(
    sessionStorage.getItem("numero") ? sessionStorage.getItem("numero") : ""
  );
  const [loadingReEnvio, setLoadingReEnvio] = useState(false);
  const [bCodigoReEnviado, setCodigoReEnviado] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogResolve, setDialogResolve] = useState(null);
  const [bTelefonoConfirmado, setTelefonoConfirmado] = useState(
    sessionStorage.getItem("numeroValido")
      ? JSON.parse(sessionStorage.getItem("numeroValido"))
      : null
  );
  const [aClavesVendedor, setClavesVendedor] = useState([]);
  const [sClaveVendedor, setClaveVendedor] = useState("");
  const [aMunicipios, setMunicipios] = useState([]);

  const [dataCuestionario, setDataCuestionario] = useState([]);
  const [bUpdateLeadInfo, setUpdateLeadInfo] = useState(false);
  /**
   * useEffect: Hook de efecto que se ejecuta después de renderizar el componente.
   * - Se define una función `getData` asincrónica que realiza una solicitud GET utilizando la URL `urlsAPIs.urlPreguntasCuestionario` y el parámetro "/616".
   * - Se obtiene la respuesta y se actualiza el estado `dataCuestionario` con los datos obtenidos de la respuesta.
   * - Se llama a la función `getData` al cargar el componente, ya que el arreglo de dependencias está vacío.
   *   Esto significa que el efecto solo se ejecutará una vez, después del primer renderizado del componente.
   * @author ERL 2023-06-16 09:41 am
   * @returns {void}
   */
  useEffect(() => {
    const getData = async () => {
      try {
        setLoadingComponent(true);
        const idUsuario = sessionStorage.getItem("u");
        const responseCuestionario = await axios.get(
          `${urlsAPIs.urlPreguntasCuestionario}/${idUsuario}`,
          {
            params: {
              sTipo: "P",
            },
            headers: headers,
          }
        );
        const responseUnidadesInteres = await axios.get(
          `${urlsAPIs.urlUnidadesInteres}/${idUsuario}`,
          { headers: headers }
        );
        const {
          sMensaje: sMensajeCuestionario,
          nCodigo: nCodigoCuestionario,
          data,
        } = responseCuestionario.data;
        const {
          sMensaje: sMensajeUnidadesInteres,
          nCodigo: nCodigoUnidadesInteres,
          data: dataUnidades,
        } = responseUnidadesInteres.data;
        const { aClavesVendedor } = data[0];
        setClavesVendedor(aClavesVendedor);
        data[0].preguntas.sort((a, b) => a.nOrden - b.nOrden);
        setDataCuestionario(data[0].preguntas);
        if (nCodigoUnidadesInteres === 0) {
          setUnidadesInteres(dataUnidades);
        } else {
          toast.error(sMensajeUnidadesInteres);
        }
      } catch (error) {
        const { responseCode = 0, message = "" } = error?.response.data;
        if (responseCode === "401" && message === "jwt expired") {
          logout();
        } else {
          toast.error(globalMessage.errorServidor);
        }
      } finally {
        setLoadingComponent(false);
      }
    };

    getData();
  }, []);

  /**
   * Maneja el envío del cuestionario. Construye un objeto `cuestionarioArray` basado en los
   * datos ingresados y realiza una solicitud POST para guardar el cuestionario completado.
   * @author ERL 2023-06-16 11:20 am
   * @param {object} data - Datos del formulario.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    // Obtener los valores necesarios de `data`
    const {
      sCodigoRef = "",
      nIdUnidad,
      sComoConocisteBesta,
      pregunta52: nIdMunicipio = "",
    } = data;

    // Eliminar las claves que contienen "sCodigoRef" o "nIdUnidad" en `data`
    Object.keys(data).forEach((key) => {
      if (
        [
          "sCodigoRef",
          "nIdUnidad",
          "sClaveVendedor",
          "pregunta48",
          "sComoConocisteBesta",
          "pregunta52",
        ].includes(key)
      ) {
        delete data[key];
      }
    });
    const arrRespuestas = [];
    for (let clave in data) {
      const valor = data[clave];
      if (Array.isArray(valor)) {
        arrRespuestas.push(...valor);
      } else {
        arrRespuestas.push(valor);
      }
    }
    const id = sessionStorage.getItem("u");
    // Crear el objeto `cuestionarioArray` con los datos necesarios para la solicitud
    const cuestionarioArray = {
      nIdUsuario: id,
      arrRespuestas: arrRespuestas,
      sCodigoRef: sCodigoRef,
      nIdUnidad: nIdUnidad,
      sComoConocisteBesta: sComoConocisteBesta,
      nIdMunicipio: nIdMunicipio,
    };
    if (sClaveVendedor !== "") {
      cuestionarioArray.sClaveVendedor = sClaveVendedor;
    }
    try {
      setLoading(true);
      //Realizar la solicitud POST utilizando la URL `urlsAPIs.urlCompletarCuestionario`
      const response = await axios.post(
        `${urlsAPIs.urlCompletarCuestionario}/${id}`,
        cuestionarioArray,
        {
          headers: headers,
        }
      );
      const {
        sMensaje: sMensajeServer,
        nCodigo,
        data: responseQuest,
      } = response.data;
      const { sMensaje: sMensajeQuest, aprovado } = responseQuest;

      if (nCodigo === 0) {
        if (aprovado) {
          nextStep();
          toast.success(sMensajeQuest, {
            duration: 3000,
            onAutoClose: (t) => {},
          });
        } else {
          sessionStorage.removeItem("t");
          navigate("/login");
          toast.error(sMensajeQuest, { duration: 5000 });
        }
      } else {
        toast.error(sMensajeServer);
      }
    } catch (error) {
      const { responseCode = 0, message = "" } = error?.response.data;
      if (responseCode === "401" && message === "jwt expired") {
        toast.error(globalMessage.labelSesionExpirada);
        logout();
      } else {
        toast.error(globalMessage.errorServidor);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Maneja la selección de una opción. Si el tipo de selección es "radio",
   * establece el valor de `nIdPreguntaRel` para el estado `selectedRadio`.
   * @author ERL 2023-06-16 09:10 am
   * @param {number} nIdPreguntaRel - ID de la pregunta relacionada seleccionada.
   * @param {string} type - Tipo de selección, ej. "radio".
   * @returns {void}
   */
  const handleSelected = (nIdPreguntaRel, type) => {
    if (type === "radio") {
      setSelectedRadio(nIdPreguntaRel);
    }
  };

  /**
   * Maneja la selección de una opción. Si el tipo de selección es "radio",
   * establece el valor de `nIdPreguntaRel` para el estado `selectedRadio`.
   * @author ERL 2023-06-16 09:26 am
   * @param {number} nIdPreguntaRel - ID de la pregunta relacionada seleccionada.
   * @param {string} type - Tipo de selección, ej. "radio".
   * @returns {void}
   */
  const handleSelectedSelect = (selectedValue, question) => {
    const relatedQuestionId = question.respuestas.find(
      (answer) => Number(answer.nIdRespMc) === Number(selectedValue)
    ).nIdPreguntaRel;
    setSelectedSelect(relatedQuestionId);
  };

  /**
   * Añade una referencia al elemento de radio en el índice dado
   * @author ERL 2023-06-17 12:16 pm
   * @param {HTMLElement} el - Elemento de radio
   * @param {number} index - Índice del elemento
   * @returns {void}
   */
  const addRef = (el, index) => {
    radioRefs.current[index] = el;
  };

  /**
   * Maneja el evento de clic en una tarjeta de opción
   * @author ERL 2023-06-17 12:18 am
   * @param {number} index - Índice de la tarjeta de opción seleccionada
   * @returns {void}
   */
  const handleCardClick = (index) => {
    //radioRefs.current[index].focus();
    radioRefs.current[index].click();
    setTimeout(() => radioRefs.current[index].blur(), 100);
  };

  /**
   * Valida el numero de teléfono con metamap, envia codigo al número del usuario
   * @author ERL 2023-08-09 11:56 am
   * @param {string} sTelefono - Teléfono para el envío del código de confirmación
   * @param {string} sField - Nombre del input dinamico
   * @returns {void}
   */
  const validarTelefono = async (sTelefono, sField) => {
    setTelefono(sTelefono);
    const nTelefono = Number(sTelefono.replaceAll("-", ""));
    const nLada = Number(lada.replaceAll("+", ""));

    const confirmed = await new Promise((resolve) => {
      setOpenDialog(true);
      setDialogResolve(() => resolve);
    });
    if (confirmed) {
      try {
        const dataToDB = {
          nIdUsuario: nIdUsuario,
          sTelefono: nTelefono,
          nClave: nLada,
        };
        const response = await axios.post(
          urlsAPIs.urlEnviarCodigoTelefono,
          dataToDB,
          { headers: headers }
        );
        const { nCodigo, sMensaje } = response.data;
        if (nCodigo === 0) {
          toast.success(sMensaje);
          //setValue("pregunta48", nTelefono);
        } else {
          toast.error(sMensaje);
        }
      } catch (error) {
        const { responseCode = 0, message = "" } = error?.response.data;
        if (responseCode === "401" && message === "jwt expired") {
          toast.error(globalMessage.labelSesionExpirada);
          logout();
        } else {
          toast.error(globalMessage.errorServidor);
        }
      }
    } else {
      setValue(sField, "");
    }
  };

  /**
   * Valida el codigo de verificación que el usuario recibio por sms
   * @author ERL 2023-08-09 04:34 pm
   * @param {string} nCodigo - Teléfono para el envío del código de confirmación
   * @param {string} sField - Nombre del input dinamico
   * @returns {void}
   */
  const validarCodigoTelefono = async (nCodigo, sField) => {
    try {
      const dataToDB = {
        nIdUsuario: nIdUsuario,
        nCodigo: nCodigo,
      };

      const response = await axios.post(
        urlsAPIs.urlConfirmarCodigoApi,
        dataToDB,
        { headers: headers }
      );
      const { nCodigo: nCodigoResponse, sMensaje } = response.data;
      if (nCodigoResponse === 0) {
        toast.success(sMensaje);
        sessionStorage.setItem("numeroValido", true);
        sessionStorage.setItem("numero", nTelefono);
        setTelefonoConfirmado(true);
      } else {
        toast.error(sMensaje);
        setValue(sField, "");
      }
    } catch (error) {
      const { responseCode = 0, message = "" } = error?.response.data;
      if (responseCode === "401" && message === "jwt expired") {
        toast.error(globalMessage.labelSesionExpirada);
        logout();
      } else {
        toast.error(globalMessage.errorServidor);
        setValue(sField, "");
      }
    }
  };

  /**
   * Maneja la confirmación del diálogo. Si se ha establecido `dialogResolve`,
   * se resuelve con `true`. Luego, cierra el diálogo.
   * @author ERL 2023-08-09 12:26 pm
   * @returns {void}
   */
  const handleDialogConfirm = () => {
    if (dialogResolve) dialogResolve(true);
    setOpenDialog(false);
  };

  /**
   * Maneja la cancelación del diálogo. Si se ha establecido `dialogResolve`,
   * se resuelve con `false`. Luego, cierra el diálogo.
   * @author ERL 2023-08-09 12:28 pm
   * @returns {void}
   */
  const handleDialogCancel = () => {
    if (dialogResolve) dialogResolve(false);
    setOpenDialog(false);
    setTimeout(() => {
      setTelefono(0);
    }, 1000);
  };

  /**
   * Reenvía el código de confirmación al teléfono del usuario
   * @author ERL 2023-08-09 12:11 pm
   * @param {string} nTelefono - Teléfono del usuario para el reenvío del código de confirmación
   * @returns {void}
   */
  const reEnviarCodigo = async (nTelefono) => {
    try {
      setLoadingReEnvio(true);
      setCodigoReEnviado(true);
      const nLada = Number(lada.replaceAll("+", ""));
      nTelefono = Number(nTelefono.replaceAll("-", ""));
      const dataToDB = {
        nIdUsuario: nIdUsuario,
        sTelefono: nTelefono,
        nClave: nLada,
      };
      const response = await axios.post(
        urlsAPIs.urlEnviarCodigoTelefono,
        dataToDB,
        { headers: headers }
      );
      const { nCodigo, sMensaje } = response.data;
      if (nCodigo === 0) {
        toast.success(sMensaje);
        setCodigoReEnviado(true);
      } else {
        toast.error(sMensaje);
      }
    } catch (error) {
      const { responseCode = 0, message = "" } = error?.response.data;
      if (responseCode === "401" && message === "jwt expired") {
        toast.error(globalMessage.labelSesionExpirada);
        logout();
      } else {
        toast.error(globalMessage.errorServidor);
      }
    } finally {
      setTimeout(() => {
        setCodigoReEnviado(false);
      }, 1000);
      setLoadingReEnvio(false);
    }
  };

  /**
   * Valida si se ingreso una clave de vendedor valida
   * @author ERL 2023-08-17 08:27 pm
   * @param {string} sClave - Clave del vendedor
   * @returns {void}
   */
  const validarClaveVendedor = (sClave, bValidacionLead = false) => {
    if (aClavesVendedor.includes(sClave)) {
      setClaveVendedor(sClave);
      if (bValidacionLead) {
        setValue("sClaveVendedor", sClave);
      }
    } else {
      setClaveVendedor("");
      if (bValidacionLead) {
        setValue("sClaveVendedor", sClave);
      }
      toast.error(LANG.errorClaveVendedor);
    }
  };
  /**
   * Valida si se ingreso una clave de vendedor valida
   * @author ERL 2023-08-17 08:27 pm
   * @param {string} sCodigo - Codigo de referido
   * @returns {void}
   */
  const validarCodigoRefereido = async (sCodigo) => {
    try {
      const response = await axios.get(
        `${urlsAPIs.urlValidarCodigoReferido}/${sCodigo}`,
        { headers: headers }
      );
      const { nCodigo, sMensaje } = response.data;
      if (nCodigo === 0) {
        toast.success(sMensaje);
        setCodigoReEnviado(true);
      } else {
        toast.error(sMensaje);
      }
    } catch (error) {
      const { responseCode = 0, message = "" } = error?.response.data;
      if (responseCode === "401" && message === "jwt expired") {
        toast.error(globalMessage.labelSesionExpirada);
        logout();
      } else {
        toast.error(globalMessage.errorServidor);
      }
    }
  };

  /**
   * Busca el listado de ciudades en base al nIdEntidad
   * @author ERL 2023-09-01 11:26 am
   * @param {int} nIdEntidad - Id de la entidad
   * @returns {void}
   *
   */
  const buscarCiudades = async (nIdEntidad, bLeadUpdate = false) => {
    if(nIdEntidad){
        setLoadingEntidad(true);
        if (bLeadUpdate) {
          setUpdateLeadInfo(true);
          setSelectedFields((prev) => ({
            ...prev,
            3: 14,
          }));
          setSelectedSelect(29);
        }
        try {
          const response = await axios.get(
            `${urlsAPIs.urlObtenerCiudades}/${nIdEntidad}`,
            { headers: headers }
          );
          const { sMensaje, nCodigo, data } = response.data;
          if (nCodigo === 0) {
            const cuidadesOptions = data;
    
            const newCiudades = [];
            cuidadesOptions?.forEach((colonias, index) => {
              newCiudades.push({
                sValor: colonias.sNombre,
                nIdRespMc: colonias.nIdMunicipio,
                nOrden: index + 1,
              });
            });
    
            /**
             * Función para actualizar la lista de ciudades dentro del array del cuestionario
             * @author ERL 2023-09-01 01:04 pm
             * @returns {void}
             */
            setDataCuestionario((prev) => {
              const clone = prev.filter(
                (dataReferencia) => dataReferencia.sTipo === "select"
              );
              let nIdentificador = 0;
              clone.forEach((dataReferencia) => {
                if (dataReferencia.sNombreCorto === "sCiudad") {
                  nIdentificador = dataReferencia.nIdentificador;
                }
              });
              const updatedData = prev.map((document) => {
                if (document.nIdentificador === nIdentificador) {
                  return { ...document, respuestas: newCiudades };
                } else {
                  return document;
                }
              });
              return updatedData;
            });
          } else {
            toast.error(sMensaje);
          }
        } catch (error) {
          const { responseCode = 0, message = "" } = error?.response.data;
          if (responseCode === "401" && message === "jwt expired") {
            toast.error(globalMessage.labelSesionExpirada);
            logout();
          } else {
            toast.error(globalMessage.errorServidor);
          }
        } finally {
          setLoadingEntidad(false);
        }
    }
  };

  const nIdProspecto = sessionStorage.getItem("p");
  const findAppsWorkLead = (sAppName, aAppsWork) => {
    return aAppsWork?.find((a) => a?.sPregLeadRel === sAppName);
  };
  const findTravelsLead = (sTraveRel, aTravels) => {
    return aTravels?.find((a) => Number(a?.sPregLeadRel) === Number(sTraveRel));
  };
  const findPlaceLead = (sPlace, aLocations) => {
    return aLocations?.find((a) => Number(a?.sPregLeadRel) === Number(sPlace));
  };
  const findChannelLead = (sChannel, aChannels) => {
    return aChannels?.find((a) => {
      if (Array.isArray(sChannel)) {
        return a?.sPregLeadRel.includes(sChannel);
      } else {
        return Number(a?.sPregLeadRel) === Number(sChannel);
      }
    });
  };

  /**
   * Obtiene la clave del vendedor asignado al lead a partir del id del vendedor.
   * @author ERL 2024-11-07 03:13pm
   * @param {string} idConcatenado - ID concatenado del cual se extrae un número para la búsqueda.
   * @param {Array} claves - Arreglo de claves donde se buscará la clave correspondiente.
   * @returns {string}
   */
  const obtenerClavePorId = (idConcatenado, claves) => {
    if(idConcatenado){
        // Extrae el número del id concatenado
        const numero = idConcatenado?.match(/\d+/)?.[0];
    
        // Busca la clave correspondiente que comience con el número extraído
        const clave = claves?.find((item) => item?.startsWith(numero));
    
        // Retorna la clave encontrada o un string vacio
        return clave || "";
    }else{
        return "";
    }
  };

  useEffect(() => {
    if (dataCuestionario.length > 0 && !bUpdateLeadInfo) {
      const aDataCuestionario = dataCuestionario.map((question) => question);

      const aAppsWork = aDataCuestionario?.find(
        (q) => q?.nIdentificador === 1
      )?.respuestas;
      const aTravels = aDataCuestionario?.find(
        (q) => q?.nIdentificador === 2
      )?.respuestas;
      const aLocations = aDataCuestionario?.find(
        (q) => q?.nIdentificador === 3
      )?.respuestas;
      const aChannels = aDataCuestionario?.find(
        (q) => q?.nIdentificador === 4
      )?.respuestas;
      if (nIdProspecto && Number(nIdProspecto) > 0) {
        const axios = require("axios");

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${urlsAPIs.getLead}lead?prospectus=${nIdProspecto}`,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer null",
          },
        };

        axios
          .request(config)
          .then((response) => {
            const data = response.data.data[0];
            const sPhone = data?.phone?.replace(/^521/, "");
            const aAppsWorklead = data?.appsWork ?  JSON.parse(data?.appsWork) : [];
            const nIdTotalTrip = data?.totalTripId;
            const nIdPlace = data?.placeId;
            const nIdChannel = data?.channelId;
            const nIdVendedor = data?.codeVendor;
            const sCodigoVendedor = obtenerClavePorId(
              nIdVendedor,
              aClavesVendedor
            );
            let aData = [];
            aAppsWorklead?.forEach((sApp) => {
              const oAppsWork = findAppsWorkLead(sApp, aAppsWork);
              aData.push(oAppsWork?.nIdRespMc);
            });            
            setValue("pregunta1", aData);
            setValue(
              "pregunta2",
              findTravelsLead(nIdTotalTrip, aTravels)?.nIdRespMc
            );
            setValue(
              "pregunta3",
              findPlaceLead(nIdPlace, aLocations)?.nIdRespMc
            );

            const nIdMTY = 1;
            const nIdGDL = 2;
            const nIdMTYMunicipio = 986
            const nIdGDLMunicipio = 570
            const oLocations = {
              [nIdMTY]: nIdMTYMunicipio, 
              [nIdGDL]: nIdGDLMunicipio, 
            };
            setValue("pregunta52", oLocations[Number(nIdPlace) ?? '']);

            buscarCiudades(
              findPlaceLead(nIdPlace, aLocations)?.nIdEntidad ?? '',
              true
            );

            setValue(
              "pregunta4",
              findChannelLead(nIdChannel, aChannels)?.nIdRespMc
            );        
            if(sCodigoVendedor){
              validarClaveVendedor(sCodigoVendedor, true);
            }
            validarTelefono(sPhone, "pregunta48");
            setValue("pregunta48", Number(sPhone));
          })
          .catch((error) => {
            console.log("🚀 ~ useEffect ~ error:", error)
            // toast.error(
            //   "Ocurrio un error al consular la informacion del cliente"
            // );
          });
      }
    }
  }, [nIdProspecto, dataCuestionario, aClavesVendedor, bUpdateLeadInfo]);
  return {
    unidadesInteres,
    loading,
    loadingComponent,
    handleSubmit,
    errors,
    control,
    selectedFields,
    setSelectedFields,
    dataCuestionario,
    visibleFields,
    selectedRadio,
    selectedSelect,
    firstInputValues,
    nTelefono,
    loadingReEnvio,
    bCodigoReEnviado,
    openDialog,
    onSubmit,
    handleSelected,
    handleSelectedSelect,
    addRef,
    handleCardClick,
    validarTelefono,
    validarCodigoTelefono,
    handleDialogConfirm,
    handleDialogCancel,
    reEnviarCodigo,
    lada,
    setLada,
    setValue,
    setVisibleFields,
    Controller,
    bTelefonoConfirmado,
    setTelefonoConfirmado,
    validarClaveVendedor,
    validarCodigoRefereido,
    aClavesVendedor,
    sClaveVendedor,
    buscarCiudades,
    loadingEntidad,
    setLoadingEntidad,
  };
}
