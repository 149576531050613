import React from 'react'
import { Box } from '@mui/material'
import { FormProvider, useFormContext } from 'react-hook-form';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import axios from 'axios';
import { toast } from 'sonner';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
import { useContext } from 'react';
import useReferencias from '../../../../../hooks/prospectosApi/pasos/useReferencias';


/**
 * Componente de formulario de referencias que recoge los datos de entrada y gestiona la presentación de los datos del formulario.
 * Este componente utiliza el proveedor de formularios de React Hook para proporcionar los métodos de control del formulario a sus hijos.
 * 
 * @author ERL 2023-07-19 07:53 pm
 * @param {array} dataReferencia - Datos de referencia del formulario
 * @param {boolean} bUltimaReferencia - Indica si la referencia actual es la última.
 * @param {int} nSessionId - Identificador único de la sesión.
 * @param {array} referenciasPendientes - Referencias pendientes
 * @param {function} setLoading - Función para manejar el estado de carga.
 * @param {function} nextRef - Función para manejar el avance a la siguiente referencia.
 * @param {JSX.Element} children - Elementos hijos del componente.
 * @param {object} methods - Métodos del formulario de React Hook Form.
 * @param {array} referenciasRechazadas - Referencias rechazadas.
 * @param {function} setReferenciasRechazadas - Actualiza la lista de referencias pendientes
 * @param {function} getData - Obtiene la lista de referencias de la API
 * 
 * 
 * @returns {JSX.Element}
 */

const ReferenciasForm = ({
    dataReferencia,
    bUltimaReferencia,
    nSessionId,
    referenciasPendientes,
    setLoading,
    nextRef,
    children,
    methods,
    nextStep,
    referenciasRechazadas,
    setReferenciasRechazadas,
    getData,
    control, handleSubmit, setError,
    errors,
    nIdPlace
}) => {

    const { headers } = useContext(ProgressBarContext)


    const { onSubmitRechazadas, onSubmit } = useReferencias({
        nSessionId,
        bUltimaReferencia,
        nextStep,
        nextRef,
        setLoading,
        dataReferencia,
        referenciasRechazadas,
        headers,
        getData,
        control, handleSubmit, setError,
        errors,
        nIdPlace
    });


    return (

        <FormProvider {...methods}>
            <Box component="form" noValidate onSubmit={methods.handleSubmit(referenciasRechazadas.length > 0 ? onSubmitRechazadas : onSubmit)} sx={{ width: '100%', }}>
                {children}
            </Box>
        </FormProvider>
    )
}

export default ReferenciasForm
